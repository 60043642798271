
import { defineComponent, PropType } from 'vue'
import QTable from 'quasar/src/components/table/QTable.js';
import { format } from 'date-fns'
import { Arrival } from '@/types/arrival'
import capitalize from 'lodash.capitalize'

export default defineComponent({
  name: 'WashListTableMobile',

  props: {
    washList: {
      type: Array as PropType<Arrival[]>,
      required: true,
    },
    loading: Boolean,
  },

  setup() {
    const columns: QTable['columns'] = [
      {
        name: 'location',
        label: 'Plats',
        field: 'location',
        align: 'left',
        sortable: false,
      },
      {
        name: 'vehicle',
        label: 'Fordon',
        field: 'vehicle',
        align: 'left',
        sortable: false,
      },
      {
        name: 'arrivalTime',
        label: 'Ankomst',
        field: 'arrivalTime',
        format: (v: string) => format(new Date(v), 'HH:mm'),
        align: 'left',
        sortable: false,
      },
      {
        name: 'status',
        label: 'Status',
        field: (row: Arrival) => row.status?.status,
        format: (v: string | null) => (v ? capitalize(v) : ''),
        align: 'left',
        sortable: false,
      },
      {
        name: '_action',
        label: '',
        field: '_action',
        align: 'left',
        sortable: false,
      },
    ]

    return {
      columns,
    }
  },
})
