
import { Ramp } from '@/types/ramp'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Ramp',

  emits: ['click'],

  props: {
    ramp: {
      type: Object as PropType<Ramp>,
    },
    loading: Boolean,
  },
})
