<template>
  <q-card style="width: 400px">
    <q-form @submit.prevent="onConfirm" autocomplete="off" greedy>
      <q-card-section>
        <div class="text-h6">Påbörja checklista</div>
      </q-card-section>
      <q-separator />

      <q-card-section>
        Vill du påbörja checklista för fordon:
        <strong>{{ arrival.vehicle }}</strong>
      </q-card-section>

      <q-separator />
      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="$emit('close')"
          :disable="loading.checkIn"
        />
        <q-btn
          flat
          label="Påbörja"
          color="primary"
          type="submit"
          :loading="loading.checkIn"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { Arrival } from '@/types/arrival'
import { defineComponent, PropType } from 'vue'
import { useArrival } from '@/composables/useArrival'

export default defineComponent({
  name: 'WashListConfirmCheckInModal',

  props: {
    arrival: {
      type: Object as PropType<Arrival>,
      required: true,
    },
  },

  emits: ['close', 'check-in'],

  setup(props, { emit }) {
    const { loading, checkIn } = useArrival()
    async function onConfirm() {
      await checkIn(props.arrival.uuid)
      emit('check-in', props.arrival.uuid)
    }

    return {
      loading,
      onConfirm,
    }
  },
})
</script>
