<template>
  <q-table
    flat
    :rows="washList"
    style="height: calc(100vh - 192px)"
    :loading="loading"
    :columns="columns"
    table-header-class="text-accent"
    class="stickyTableHeader full-width"
    :rows-per-page-options="[0]"
  >
    <template v-slot:body-cell-_action="{ props }">
      <q-td :props="props">
        <q-icon size="sm" name="mdi-chevron-right" color="accent" />
      </q-td>
    </template>
  </q-table>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { QTable } from 'quasar'
import { format } from 'date-fns'
import { Arrival } from '@/types/arrival'
import capitalize from 'lodash.capitalize'

export default defineComponent({
  name: 'WashListTableMobile',

  props: {
    washList: {
      type: Array as PropType<Arrival[]>,
      required: true,
    },
    loading: Boolean,
  },

  setup() {
    const columns: QTable['columns'] = [
      {
        name: 'location',
        label: 'Plats',
        field: 'location',
        align: 'left',
        sortable: false,
      },
      {
        name: 'vehicle',
        label: 'Fordon',
        field: 'vehicle',
        align: 'left',
        sortable: false,
      },
      {
        name: 'arrivalTime',
        label: 'Ankomst',
        field: 'arrivalTime',
        format: (v: string) => format(new Date(v), 'HH:mm'),
        align: 'left',
        sortable: false,
      },
      {
        name: 'status',
        label: 'Status',
        field: (row: Arrival) => row.status?.status,
        format: (v: string | null) => (v ? capitalize(v) : ''),
        align: 'left',
        sortable: false,
      },
      {
        name: '_action',
        label: '',
        field: '_action',
        align: 'left',
        sortable: false,
      },
    ]

    return {
      columns,
    }
  },
})
</script>
