
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WashListCompletedArrivalProgress',
  props: {
    max: Number,
    value: Number,
  },
})
