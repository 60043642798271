<template>
  <transition
    enter-active-class="animated animate__zoomIn"
    leave-active-class="animated animate__zoomOut"
    :duration="140"
    appear
  >
    <q-circular-progress
      show-value
      :min="0"
      :max="max"
      :value="value"
      size="58px"
      :thickness="0.14"
      color="primary"
      class="q-mt-md q-mr-md"
      track-color="grey-3"
    >
      <span class="text-grey-7 text-overlinie">{{ value }}/{{ max }}</span>
    </q-circular-progress>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WashListCompletedArrivalProgress',
  props: {
    max: Number,
    value: Number,
  },
})
</script>
