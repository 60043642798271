
import { Arrival } from '@/types/arrival'
import { defineComponent, PropType } from 'vue'
import { useArrival } from '@/composables/useArrival'

export default defineComponent({
  name: 'WashListConfirmCheckInModal',

  props: {
    arrival: {
      type: Object as PropType<Arrival>,
      required: true,
    },
  },

  emits: ['close', 'check-in'],

  setup(props, { emit }) {
    const { loading, checkIn } = useArrival()
    async function onConfirm() {
      await checkIn(props.arrival.uuid)
      emit('check-in', props.arrival.uuid)
    }

    return {
      loading,
      onConfirm,
    }
  },
})
