import { instance } from '@/services/klarbuss-api'
import { ArrivalTask } from '@/types/arrival-task'
import { AxiosResponse } from 'axios'

export function completeArrivalTask(
  arrivalUuid: string,
  arrivalTaskUuid: string
): Promise<AxiosResponse<ArrivalTask>> {
  return instance.post(`/arrival/${arrivalUuid}/completed-arrival-task`, {
    arrivalTaskUuid,
  })
}
