import { render } from "./RampCard.vue?vue&type=template&id=0e2d2b61"
import script from "./RampCard.vue?vue&type=script&lang=ts"
export * from "./RampCard.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QSkeleton});
