import { Arrival } from '@/types/arrival'
import { ref, Ref } from 'vue'
import { getArrival } from '@/api/arrival/getArrival'
import { checkIn as checkInApi } from '@/api/arrival/checkIn'
import { checkOut as checkOutApi } from '@/api/arrival/checkOut'
import { completeArrivalTask } from '@/api/arrival/completeArrivalTask'
import { ArrivalTask } from '@/types/arrival-task'

interface UseArrival {
  arrival: Ref<Arrival | null>
  loading: Ref<Loading>
  fetchArrival: (arrivalUuid: string) => Promise<Arrival>
  checkIn: (arrivalUuid: string) => Promise<void>
  checkOut: (arrivalUuid: string) => Promise<void>
  completeTask: (
    arrivalUuid: string,
    arrivalTaskUuid: string
  ) => Promise<ArrivalTask>
}

interface Loading {
  checkIn: boolean
  checkOut: boolean
  get: boolean
  completeTaskUuid: string | null
}

export const useArrival = (): UseArrival => {
  const arrival = ref<Arrival | null>(null)
  const loading = ref<Loading>({
    get: false,
    checkIn: false,
    checkOut: false,
    completeTaskUuid: null,
  })

  async function fetchArrival(arrivalUuid: string) {
    loading.value.get = true
    const { data } = await getArrival(arrivalUuid)
    arrival.value = data
    loading.value.get = false
    return Promise.resolve(data)
  }

  async function checkIn(arrivalUuid: string) {
    loading.value.checkIn = true
    await checkInApi(arrivalUuid)
    loading.value.checkIn = false
  }

  async function checkOut(arrivalUuid: string) {
    loading.value.checkOut = true
    await checkOutApi(arrivalUuid)
    loading.value.checkOut = false
  }

  async function completeTask(arrivalUuid: string, arrivalTaskUuid: string) {
    loading.value.completeTaskUuid = arrivalTaskUuid
    const { data } = await completeArrivalTask(arrivalUuid, arrivalTaskUuid)
    loading.value.completeTaskUuid = null

    return Promise.resolve(data)
  }

  return {
    completeTask,
    fetchArrival,
    checkIn,
    checkOut,
    arrival,
    loading,
  }
}
