import { render } from "./WashList.vue?vue&type=template&id=ccfb9424"
import script from "./WashList.vue?vue&type=script&lang=ts"
export * from "./WashList.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QDialog,QInput,QIcon,QPopupProxy,QDate,QSeparator});
