<template>
  <q-page class="flex">
    <q-dialog v-model="showConfirmModal" persistent>
      <WashListConfirmCheckInModal
        :arrival="selectedArrival"
        v-if="showConfirmModal"
        @close="showConfirmModal = false"
        @check-in="goToArrival"
      />
    </q-dialog>
    <div class="full-width">
      <div class="q-px-md bg-white">
        <q-input
          class="full-width"
          hide-bottom-space
          borderless
          readonly
          @click.capture="
            (e) => {
              e.stopImmediatePropagation()
              datePopupRef?.show()
            }
          "
          v-model="date"
        >
          <template v-slot:prepend>
            <q-icon name="mdi-calendar" class="cursor-pointer">
              <q-popup-proxy
                ref="datePopupRef"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  first-day-of-week="1"
                  mask="YYYY-MM-DD"
                  today-btn
                  @update:model-value="datePopupRef?.hide"
                  v-model="date"
                />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>

      <q-separator />

      <RampCard :loading="loadingRamp" :ramp="ramp" flat>
        <WashListCompletedArrivalProgress
          v-if="washList.length"
          :value="completedCount"
          :max="washList.length"
        />
      </RampCard>

      <div v-if="loadingRamp">
        <pre>
          {{ ramp }}
        </pre>
      </div>
    </div>

    <WashListTableMobile
      @row-click="onRowClick"
      :loading="loadingWashList"
      :wash-list="washList"
    />
  </q-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { Arrival } from '@/types/arrival'
import { getRampsByUuid } from '@/api/ramp/getRampsByUuid'
import { useRoute, useRouter } from 'vue-router'
import { getWashList } from '@/api/wash-list/getWashList'
import { Ramp } from '@/types/ramp'
import RampCard from '@/components/ramp/RampCard.vue'
import WashListTableMobile from '@/components/wash-list/WashListTableMobile.vue'
import WashListConfirmCheckInModal from '@/components/wash-list/WashListConfirmCheckInModal.vue'
import WashListCompletedArrivalProgress from '@/components/wash-list/WashListCompletedArrivalProgress.vue'

export default defineComponent({
  name: 'WashList',

  components: {
    RampCard,
    WashListTableMobile,
    WashListConfirmCheckInModal,
    WashListCompletedArrivalProgress,
  },

  setup() {
    const router = useRouter()
    const selectedArrival = ref<Arrival | null>(null)
    const showConfirmModal = ref(false)
    const route = useRoute()
    const ramp = ref<Ramp | null>(null)
    const datePopupRef = ref(null)
    const loadingRamp = ref(true)
    const loadingWashList = ref(false)
    const washList = ref<Arrival[]>([])

    if (typeof route.params.trafficDate !== 'string') {
      throw new Error('No traffic date param available')
    }

    function getRampUuidParam() {
      if (typeof route.params.rampUuid !== 'string') {
        throw new Error('No ramp uuid param available')
      }

      return route.params.rampUuid
    }

    const date = ref(route.params.trafficDate)

    async function fetchRampsByUuid() {
      loadingRamp.value = true
      const { data } = await getRampsByUuid(getRampUuidParam())
      ramp.value = data
      loadingRamp.value = false
    }

    async function fetchWashList() {
      if (!ramp.value) return
      router.push(`/mobile/wash-list/${route.params.rampUuid}/${date.value}`)
      loadingWashList.value = true
      try {
        const data = await getWashList(date.value, ramp.value.names)
        washList.value = data
      } catch (error) {
        console.log(error)
        washList.value = []
      } finally {
        loadingWashList.value = false
      }
    }

    async function fetchAllData() {
      await fetchRampsByUuid()
      await fetchWashList()
    }

    watch(date, fetchWashList)

    fetchAllData()

    function goToArrival(arrivalUuid: string) {
      router.push(
        `/mobile/checklist/${getRampUuidParam()}/${date.value}/${arrivalUuid}`
      )
    }

    function onRowClick(e: Event, arrival: Arrival) {
      if (arrival.status?.status) {
        goToArrival(arrival.uuid)
      } else {
        selectedArrival.value = arrival
        showConfirmModal.value = true
      }
    }

    const completedCount = computed(
      () =>
        washList.value.filter(
          (arrival) => arrival.status?.status === 'avslutad'
        ).length
    )

    return {
      completedCount,
      goToArrival,
      selectedArrival,
      date,
      datePopupRef,
      loadingRamp,
      loadingWashList,
      ramp,
      washList,
      showConfirmModal,
      onRowClick,
    }
  },
})
</script>
